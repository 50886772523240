var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Invoice "+_vm._s(_vm.invoice.id))])])],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"From Address"},model:{value:(_vm.invoice.attributes.fromAddress),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "fromAddress", $$v)},expression:"invoice.attributes.fromAddress"}}),_c('v-autocomplete',{attrs:{"label":"Customer","options":"['NET 30','NET 5']"}}),_c('v-textarea',{attrs:{"label":"Client Address"},model:{value:(_vm.invoice.attributes.billingAddress),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "billingAddress", $$v)},expression:"invoice.attributes.billingAddress"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Invoice Number"},model:{value:(_vm.invoice.attributes.id),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "id", $$v)},expression:"invoice.attributes.id"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Invoice Date","prepend-icon":_vm.iconCalendar,"readonly":""},model:{value:(_vm.invoice.attributes.date),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "date", $$v)},expression:"invoice.attributes.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuInvoice),callback:function ($$v) {_vm.dateMenuInvoice=$$v},expression:"dateMenuInvoice"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenuInvoice = false}},model:{value:(_vm.invoice.attributes.date),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "date", $$v)},expression:"invoice.attributes.date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Due Date","prepend-icon":_vm.iconCalendar,"readonly":""},model:{value:(_vm.invoice.attributes.dueDate),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "dueDate", $$v)},expression:"invoice.attributes.dueDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuDue),callback:function ($$v) {_vm.dateMenuDue=$$v},expression:"dateMenuDue"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenuDue = false}},model:{value:(_vm.invoice.attributes.dueDate),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "dueDate", $$v)},expression:"invoice.attributes.dueDate"}})],1),_c('v-select',{attrs:{"label":"Terms","options":"['NET 30']","invoice.attributes.terms":""}})],1)],1),_c('v-data-table',{staticClass:"mt-10",attrs:{"headers":_vm.headers,"fixed-header":true,"disable-pagination":true,"hide-default-footer":true,"items":_vm.invoice.attributes.lines},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Date"},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":"Description"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"width":"50px"},attrs:{"label":"Quantity"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rate)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"comments pt-3",attrs:{"colspan":"3","rowspan":"5"}},[_c('v-textarea',{attrs:{"label":"Comments"},model:{value:(_vm.invoice.attributes.comments),callback:function ($$v) {_vm.$set(_vm.invoice.attributes, "comments", $$v)},expression:"invoice.attributes.comments"}})],1),_c('th',{staticClass:"text-uppercase text-no-wrap"},[_vm._v("Subtotal")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.invoice.attributes.subtotal / 100))+" ")])]),(_vm.invoice.attributes.discount > 0)?_c('tr',[_c('th',{staticClass:"text-uppercase text-no-wrap"},[_vm._v("DISCOUNT")]),_c('td',{staticClass:"text-right"},[_vm._v(" -"+_vm._s(_vm.numberFormat(_vm.invoice.attributes.discount / 100))+" ")])]):_vm._e(),(_vm.invoice.attributes.tax > 0)?_c('tr',[_c('th',{staticClass:"text-uppercase text-no-wrap"},[_vm._v("HST (ON) @ 13%")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.invoice.attributes.tax / 100))+" ")])]):_vm._e(),_c('tr',[_c('th',{staticClass:"text-uppercase text-no-wrap"},[_vm._v("TOTAL")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.invoice.attributes.total / 100))+" ")])]),_c('tr',[_c('th',{staticClass:"text-uppercase text-no-wrap"},[_vm._v("BALANCE DUE")]),_c('td',{staticClass:"text-right text-no-wrap"},[_c('span',{staticClass:"balance"},[_vm._v(" "+_vm._s(_vm.invoice.attributes.currency)+" "+_vm._s(_vm.numberFormat(_vm.invoice.attributes.total / 100))+" ")])])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }