















































































































































import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import {invoiceModule} from '@/store/modules';
import {InvoiceResource} from '@/interfaces';
import {mdiCalendar} from '@mdi/js';
import {numberFormat} from '@/filters';
import {InvoiceType} from '@/enums';

@Component({
  components: {},
})
export default class InvoiceView extends Vue {
  iconCalendar = mdiCalendar;
  dateMenuInvoice = false;
  dateMenuDue = false;
  numberFormat = numberFormat;

  get headers(): Record<string, string>[] {
    return [
      {
        text:
          this.invoice.attributes.type === InvoiceType.Service
            ? 'DATE'
            : 'ITEM',
        value:
          this.invoice.attributes.type === InvoiceType.Service
            ? 'date'
            : 'item',
        cellClass: 'text-no-wrap',
        width: '8rem',
      },
      {
        text: 'DESCRIPTION',
        value: 'description',
        cellClass: '',
        width: '100%',
      },
      {
        text: 'QTY',
        value: 'quantity',
        cellClass: 'text-no-wrap',
        width: '3rem',
      },
      {
        text: 'RATE',
        value: 'rate',
        cellClass: 'text-no-wrap',
        width: '4rem',
      },
      {
        text: 'AMOUNT',
        value: 'amount',
        align: 'end',
        cellClass: 'text-no-wrap',
        width: '4rem',
      },
    ];
  }

  get invoice(): InvoiceResource {
    return invoiceModule.find(this.$route.params.invoiceId);
  }

  get loading(): boolean {
    return invoiceModule.loading;
  }

  async created(): Promise<void> {
    await invoiceModule.loadById(this.$route.params.invoiceId);
  }
}
